import { UserGqlError, UserNanoID, UserUUID } from '../../usersTypes';
import { FetchItemCacheKey } from '../../../../types';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleUserBlockedTermsAcceptedOptions<
  ToggleUserBlockedTermsAcceptedRecordType
> {
  query: string;
  cacheKeys?: FetchItemCacheKey[];
  onSuccess?: (
    responseRecord: ToggleUserBlockedTermsAcceptedRecordType
  ) => void;
}

export interface ToggleUserBlockedTermsAcceptedResponse<
  ToggleUserBlockedTermsAcceptedRecordType
> {
  toggleUserBlockedTermsAccepted: {
    status: string;
    record: ToggleUserBlockedTermsAcceptedRecordType;
    errors: ToggleUserBlockedTermsAcceptedError;
  };
}

export interface ToggleUserBlockedTermsAcceptedInput {
  uuid: UserUUID | UserNanoID;
}

export interface ToggleUserBlockedTermsAcceptedError {
  fullMessages: UserGqlError;
}

const action = 'toggleUserBlockedTermsAccepted';

function useToggleUserBlockedTermsAccepted<
  ToggleUserBlockedTermsAcceptedRecordType
>({
  query,
  cacheKeys,
  onSuccess
}: ToggleUserBlockedTermsAcceptedOptions<ToggleUserBlockedTermsAcceptedRecordType>) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleUserBlockedTermsAcceptedInput,
    ToggleUserBlockedTermsAcceptedResponse<ToggleUserBlockedTermsAcceptedRecordType>,
    ToggleUserBlockedTermsAcceptedError,
    ToggleUserBlockedTermsAcceptedRecordType
  >({ action, query, cacheKeys, onSuccess });

  return {
    toggleUserBlockedTermsAcceptedData: updateQueryData,
    toggleUserBlockedTermsAcceptedError: updateQueryError,
    toggleUserBlockedTermsAcceptedLoading: updateQueryLoading,
    toggleUserBlockedTermsAcceptedErrorMessage: updateQueryErrorMessage,
    toggleUserBlockedTermsAccepted: updateQuery,
    toggleUserBlockedTermsAcceptedReset: updateQueryReset
  };
}

export default useToggleUserBlockedTermsAccepted;
